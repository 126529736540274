import React, { useRef, useState } from 'react'
import SignUpScreen from '../SingupScreen/SignUpScreen';
import './LoginScreen.css'
import Footer from '../../components/Footer';
import NavForHome from '../../components/Nav/NavForHome';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQcomponent from '../../components/FAQcomponent/FAQcomponent';

function LoginScreen() {
    const [signin, setsignin] = useState(false);
    const [featureshow, setfeatureshow] = useState(1);
    const [showearnings, setshowearnings] = useState(1);
    const myRef = useRef(null)
    const navigate = useNavigate()

    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    return (
        <>
            <Helmet>
                <title>Premsi - Easily sell your digital content❤️</title>
                <meta name="description" content="Create a Premsi account and use Premsi + sell immediately." />
            </Helmet>
            <NavForHome setsignin={setsignin} signin={signin} />

            <div className='loginscreen' ref={myRef}>

                <div style={{ width: "100%", height: "100vh", backgroundColor: "rgba(0, 0, 0, 0.4)", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className='loginscreen__body'>
                        {signin ?
                            <SignUpScreen />
                            :
                            <>
                                <h1>Sell, Market and explore content</h1>
                                {/*<h2 style={{ color: "white" }}>Enjoy / Buy / Sell / Market your digital content</h2>*/}
                                {/*<h2 style={{ color: "white" }}>Use Premsi on our App and WebApp</h2>*/}
                                <h3 style={{ color: "white" }}>Join the most monetizable social media!</h3>
                                <h3 style={{ color: "white" }}>Ready to join? Download our App or sign up here for free!</h3>
                                <div className='loginscreen__input'>
                                    <form>
                                        {/* <input className='login_input' type="email" placeholder='E-mail address' />*/}
                                        <button className='loginscreen__getStarted' onClick={() => setsignin(true)}>ENJOY</button>
                                    </form>
                                </div>
                                <div className='appstoreouter'>
                                    <div className='appstoreinner'>
                                        <a href="https://apps.apple.com/app/apple-store/id6446628909" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" src={require('../../images/appstore.webp')} style={{ cursor: "pointer", height: 45, borderRadius: 5 }} alt="Premsi.com App store link" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.Premsi.app" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" src={require('../../images/googleplay.webp')} style={{ cursor: "pointer", height: 45, borderRadius: 5, borderColor: "black", borderWidth: 1, borderStyle: "solid" }} alt="Premsi.com Google play store link" /></a>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div >
            </div >

            <div className='whatispremsimainmain'>
                <div className='whatispremsitop' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 23, fontWeight: "bold", color: "black" }}>What is Premsi? </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ marginLeft: 10, height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>

                <p className='premsiexplanationp'>Premsi is the social media where you can see the more private side of your favorite creator 🔥</p>

                <div className='whatispremsimainhome'>
                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>⏱</p>
                        <p>Sign Up and Sell in 30sec 🚀</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>🏦</p>
                        <p>Premsi is free</p>
                        <p>85% daily payout</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>⭐️</p>
                        <p>Exploring / Marketing / Selling All in One</p>
                    </div>

                    <div className='whatispremsidivhome'>
                        <p style={{ fontSize: 30, marginTop: -20 }}>🔥</p>
                        <p style={{ fontSize: 19 }}>Fans can buy your content without logging in</p>
                    </div>
                </div>

                {/* here have social media icons */}

                <div className='socialbar' >
                    <p style={{ fontSize: 15, opacity: 0.75 }}>seen on</p>
                    <div className='innersocialbigger' >
                        <img className='socialbarimage' alt='Hacker news logo' src={require('../../images/socialforbar/hn.webp')} />
                        <img className='socialbarimage' alt='Twitter X logo' src={require('../../images/socialforbar/x.webp')} />
                    </div>
                    <div className='innersocialbigger' >
                        <img className='socialbarimage' alt='Tiktok logo' src={require('../../images/socialforbar/tt.webp')} />
                        <img className='socialbarimage' alt='Product Hunt logo' src={require('../../images/socialforbar/producthunt.webp')} />
                    </div>
                    <div className='innersocialsmaller' >
                        <img className='socialbarimage' alt='Instagram logo' src={require('../../images/socialforbar/ig.webp')} />
                    </div>
                </div>

                {/*Here start explained our features */}
                <div style={{ display: "flex", marginTop: 50, alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
                    <p style={{ fontSize: 23, fontWeight: "bold", color: "black" }}>8+ benefits of Premsi </p>
                    <img loading="lazy" src={require('../../images/pblue-min.webp')} style={{ marginLeft: 5, height: 25, width: 25, borderRadius: 15 }} alt="Premsi.com logo app icon" />
                </div>
                <div style={{ display: "flex", marginTop: 0, alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                    <p style={{ fontSize: 15, fontWeight: "bold", color: "black" }}>4+ different income streams with Premsi</p>
                </div>

                <div className='featureouterdiv'>
                    <div className='featureinnerdiv'>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(1)} className='featurebtn' style={{ backgroundColor: featureshow === 1 ? "#009FFD" : null }}>Explore 🚀</div>
                            <div onClick={() => setfeatureshow(2)} className='featurebtn' style={{ backgroundColor: featureshow === 2 ? "#009FFD" : null }}>Buy 🔥</div>
                        </div>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(3)} className='featurebtn' style={{ backgroundColor: featureshow === 3 ? "#009FFD" : null }}>Sell ✍️</div>
                            <div onClick={() => setfeatureshow(4)} className='featurebtn' style={{ backgroundColor: featureshow === 4 ? "#009FFD" : null }}>Groups 👥</div>
                        </div>
                    </div>
                    <div className='featureinnerdiv'>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(5)} className='featurebtn' style={{ backgroundColor: featureshow === 5 ? "#009FFD" : null }}>Content 🏞</div>
                            <div onClick={() => setfeatureshow(6)} className='featurebtn' style={{ backgroundColor: featureshow === 6 ? "#009FFD" : null }}>Marketing 🍿</div>
                        </div>
                        <div className='featurebtndiv'>
                            <div onClick={() => setfeatureshow(7)} className='featurebtn' style={{ backgroundColor: featureshow === 7 ? "#009FFD" : null }}>30sec rule ⏱</div>
                            <div onClick={() => setfeatureshow(8)} className='featurebtn' style={{ backgroundColor: featureshow === 8 ? "#009FFD" : null }}>App 👩‍💻</div>
                        </div>
                    </div>
                </div>

                <div className='featureexplanationmaindiv'>
                    {featureshow === 1 ?
                        <div className='featureexplanationinnerdiv'>
                            <div className='featureexplanationhalfdiv'>
                                <img loading="lazy" style={{ width: "80%" }} src={require('../../images/homepresentationphotos/1.webp')} alt="Premsi.com feed to explore" />
                            </div>
                            <div className='featureexplanationhalfdiv'>
                                <h3>Explore content on our App and our Website 🚀</h3>
                                <h4>Through the sharing of normal posts you can market your premium messages or your groups!</h4>
                            </div>
                        </div>
                        :
                        (featureshow === 2 ?
                            <div className='featureexplanationinnerdiv'>
                                <div className='featureexplanationhalfdiv'>
                                    <h3>Easily buy Premium Messages ❤️</h3>
                                    <h4>You can buy Premium Photos/Videos/PDFs easily in the App or on the Website!</h4>
                                </div>
                                <div className='featureexplanationhalfdiv'>
                                    <img loading="lazy" style={{ width: "80%" }} className='featureexplanationimagebelow' src={require('../../images/homepresentationphotos/2.webp')} alt="Premsi.com Easily buy Premium Messages" />
                                </div>
                            </div>
                            : (featureshow === 3 ?
                                <div className='featureexplanationinnerdiv'>
                                    <div className='featureexplanationhalfdiv'>
                                        <img loading="lazy" style={{ width: "80%" }} src={require('../../images/homepresentationphotos/3.webp')} alt="Premsi.com Easily Create and Sell Premium Messages" />
                                    </div>
                                    <div className='featureexplanationhalfdiv'>
                                        <h3>Easily Create and Sell Premium Messages 🎉</h3>
                                        <h4>Your Premium Messages can be easily bought by your fans through our App or the website!</h4>
                                    </div>
                                </div>
                                : (featureshow === 4 ?
                                    <div className='featureexplanationinnerdiv'>
                                        <div className='featureexplanationhalfdiv'>
                                            <h3>Create and Join free or paid Groups in seconds ⭐️</h3>
                                            <h4> You can easily monetize your groups and earn with monthly subscriptions or special tips. Your Premium messages can be also marketed in your group!</h4>
                                        </div>
                                        <div className='featureexplanationhalfdiv'>
                                            <img loading="lazy" style={{ width: "80%" }} className='featureexplanationimagebelow' src={require('../../images/homepresentationphotos/4.webp')} alt="Premsi.com Create and Join free or paid Groups in seconds" />
                                        </div>
                                    </div>
                                    : (featureshow === 5 ?
                                        <div className='featureexplanationinnerdiv'>
                                            <div className='featureexplanationhalfdiv'>
                                                <img loading="lazy" style={{ width: "80%" }} src={require('../../images/homepresentationphotos/5.webp')} alt="Premsi.com You can share free and paid photos, videos, PDFs, create groups and soon offer courses." />
                                            </div>
                                            <div className='featureexplanationhalfdiv'>
                                                <h3>Share every type of content 🏞</h3>
                                                <h4>You can share free and paid photos, videos, PDFs, create groups and soon offer courses and paid podcasts.</h4>
                                            </div>
                                        </div>
                                        : (featureshow === 6 ?
                                            <div className='featureexplanationinnerdiv'>
                                                <div className='featureexplanationhalfdiv'>
                                                    <h3>Easily market your Premium Content with Free Posts 🍿</h3>
                                                    <h4>You can link your normal posts directly to your Groups and Premium Messages. Users can access your content on every Platform without even logging in!</h4>
                                                    <h4>Easily share your free and paid content to other social media to get even more fans and customers on Premsi!</h4>
                                                </div>
                                                <div className='featureexplanationhalfdiv'>
                                                    <img loading="lazy" style={{ width: "80%" }} className='featureexplanationimagebelow' src={require('../../images/homepresentationphotos/6.webp')} alt="Premsi.com Easily market your Premium Content with Free Posts" />
                                                </div>
                                            </div>
                                            : (featureshow === 7 ?
                                                <div className='featureexplanationinnerdiv'>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <img loading="lazy" style={{ width: "80%" }} src={require('../../images/homepresentationphotos/7.webp')} alt="Premsi.com Fans can buy Premium Messages in less than 30seconds" />
                                                    </div>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <h3>Fans can buy Premium Messages in less than 30seconds ⏱</h3>
                                                        <h4>Fans are able to buy your premium content in 30 seconds without even creating an account, making the purchase process faster and easier!</h4>
                                                        <h4>Your Premium Content will be delivered in a secure way that is not shareable to others!</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className='featureexplanationinnerdiv'>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <h3>Our App makes everything easier 👩‍💻</h3>
                                                        <h4>Through our App you can create Premium and normal content, create groups, manage your account and most importantly: create payouts!</h4>
                                                    </div>
                                                    <div className='featureexplanationhalfdiv'>
                                                        <img loading="lazy" style={{ width: "80%" }} className='featureexplanationimagebelow' src={require('../../images/homepresentationphotos/8.webp')} alt="Premsi.com Our App makes everything easier" />
                                                    </div>
                                                </div>
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    }
                </div>
                {/*Here end explained our features */}


                {/*<button className='faq__button' onClick={() => (navigate("/faq"))}>Our FAQs</button>*/}
            </div>

            <div className='everyuserdiv'>
                <div className='everyuserdivinner'>
                    <h3>Every user can easily become a creator and sell on Premsi in minutes</h3>
                    {/*<h3 style={{ marginBottom: 15 }}>Premsi generated over 450.000$ for creators 💰</h3>*/}
                    {/*<button className='loginnormal__button' onClick={() => (executeScroll(), setsignin(true))}>Let's go</button>*/}
                </div>
            </div>

            <div className='everyuserdivsecond'>
                <div className='everyuserdivinnersecond'>
                    <p style={{ fontSize: 24, fontWeight: "bold", marginTop: 40, color: "white" }}>Fees 💰</p>
                    <p className='priceexplanation'>And all that for 0$. No monthly subscription. No credit card required. Nothing!</p>
                    <p className='priceexplanation'>We only earn once you make money and 85% goes directly to you</p>
                </div>
            </div>

            <div className='explainappdiv'>
                <h2>Easy to use App 🔥</h2>
                <div className='explainappinnerdiv'>
                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>1️⃣ The feed to explore/market</h4>
                        <img loading="lazy" className='explainappthirdimgnormal' src={require('../../images/pic1.webp')} alt="Premsi.com feed to explore" />
                    </div>

                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>2️⃣ Buy premium messages</h4>
                        <img loading="lazy" className='explainappthirdimgnormal' src={require('../../images/premiummessage.webp')} alt="Premsi.com easily buy premium messages" />
                    </div>

                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>3️⃣ Easily sell premium messages</h4>
                        <img loading="lazy" className='explainappthirdimg' src={require('../../images/pic2.webp')} alt="Premsi.com easily sell premium messages" />
                    </div>

                    <div className='explainappthirddiv'>
                        <h4 style={{ color: "black", textAlign: "center", marginBottom: 20 }}>4️⃣ Create free/paid subscription groups</h4>
                        <img loading="lazy" className='explainappthirdimg' src={require('../../images/groups1.webp')} alt="Premsi.com easily create free/paid subscription groups" />
                    </div>
                </div>
            </div>

            {/* FAQ section */}
            <FAQcomponent />

            <div className='referralmaindiv'>
                <div className='referralinnerdiv'>
                    <div className='referralfirstinnerdiv'>
                        <h2 style={{ color: "white", width: "85%" }}>Refer friends & receive 5% of their spendings for life 🔥</h2>
                        <h4 style={{ width: "90%" }}>Whenever they subscribe to Premsi+, tip someone or buy a premium message you'll earn.</h4>
                        <button className='referfriend__button' onClick={() => (executeScroll(), setsignin(true))}>Refer a friend</button>
                    </div>

                    <div className='referralfirstinnerdiv'>
                        <img loading="lazy" style={{ height: "100%" }} src={require('../../images/referral.webp')} alt="Premsi.com referral bonus" />
                    </div>
                </div>
            </div>

            {/*<div className='explainappdivwhatcreatorsachieved'>
                <h2>What our creators achieve ❤️</h2>

                {showearnings === 1 ?
                    <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/1pm.webp')} alt="Premsi.com earnings example" />
                    : (showearnings === 2 ?
                        <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/2pm.webp')} alt="Premsi.com earnings example" />
                        : (showearnings === 3 ?
                            <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/3pm.webp')} alt="Premsi.com earnings example" />
                            : (showearnings === 4 ?
                                <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/4pm.webp')} alt="Premsi.com earnings example" />
                                :
                                <img loading="lazy" className='creatorsachievedimage' src={require('../../images/pmearnings/5pm.webp')} alt="Premsi.com earnings example" />
                            )))}

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 14 }}>
                    <div onClick={() => setshowearnings(1)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 1 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(2)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 2 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(3)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 3 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(4)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 4 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                    <div onClick={() => setshowearnings(5)} style={{ cursor: "pointer", height: 14, width: 14, borderRadius: 10, backgroundColor: (showearnings === 5 ? "gray" : "lightgray"), marginRight: 10 }}></div>
                </div>
            </div>*/}

            <div style={{ width: "100%", marginBottom: 30, marginTop: 30, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <h3 style={{ color: "black", marginBottom: 10, width: "80%", textAlign: "center" }}>Join our community today 🎉</h3>
                <h5 style={{ color: "black", marginBottom: 15, width: "80%", textAlign: "center" }}>Explore all of our exciting features in our App and on our website!</h5>
                <button className='loginnormal__button' onClick={() => (executeScroll(), setsignin(true))}>Sign in</button>
                <div className='appstoreinner'>
                    <a href="https://apps.apple.com/app/apple-store/id6446628909" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" src={require('../../images/appstore.webp')} style={{ cursor: "pointer", height: 40, borderRadius: 5 }} alt="Premsi.com App store link" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.Premsi.app" style={{ cursor: "pointer", display: "flex", alignItems: "center" }} target='_blank'><img loading="lazy" src={require('../../images/googleplay.webp')} style={{ cursor: "pointer", height: 40, borderRadius: 5, borderColor: "black", borderWidth: 1, borderStyle: "solid" }} alt="Premsi.com Google play store link" /></a>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default LoginScreen


